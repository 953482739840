import { Row, Col, Button ,Tree, Typography, Checkbox, Input, Select   } from 'antd';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Category } from '@/types';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Portal } from '@/pages/Portal/types';
import { getList } from '@/pages/Portal/services/portal.services';
import { Company } from '@/pages/Company/types';
import { getList as getListCompany } from '@/pages/Company/services/company.services';
import styled from 'styled-components';

const { Title } = Typography;

const GenderStyle = styled.div`
  .ant-checkbox-group {
    flex-wrap: wrap;
  }
`;

const Filters = ({
  treeData, 
  callBack, 
  genderOptions, 
  categories, 
  clearFilterEvent,
  treeStateChangeEvent,
  allowShuffle,
  allowShuffleEvt,
  parentCategoriesCheckedStateEvt,
}) => {
  //forceUpdate khi cập nhật limit
  const [, updateState] = useState();
  // @ts-ignore
  const forceUpdate = useCallback(() => updateState({}), []);

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [checkedGenders, setCheckedGenders] = useState<CheckboxValueType[]>([]);
  const [limitCategories, setLimitCategories] = useState<Category[]>([]);
  
  // used field
  const [maxTimes, setMaxTimes] = useState<number>(0);
  const [maxTimesChecked, setMaxTimesChecked] = useState<boolean>(false);

  //begin - ignore what is used in {portal} , {company}
  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [portalList, setPortalList] = useState<Portal[]>([]);
  const [companiesSelected, setCompaniesSelected] = useState<number[]>([]);
  const [portalsSelected, setPortalsSelected] = useState<number[]>([]);
  const [ignoreChecked, setIgnoreChecked] = useState<boolean>(false);

  // no tags query
  const [parentCategoriesChecked, setParentCategoriesChecked] = useState<string[]>([]);
  const [noTagsChecked, setNoTagsChecked] = useState<string[]>([]);

  useEffect(() => {
    getList().then((res) => {
      setPortalList(res);
    });
    getListCompany().then(res => {
      setCompanyList(res);
    })
  }, []);
  const handlePortalChange = (portalIds: number[]) => {
    setPortalsSelected(portalIds);
  };
  const handleCompanyChange = (companyIds: number[]) => {
    setCompaniesSelected(companyIds);
  };
  //end - ignore what is used in {portal}

  const onExpand = (expandedKeysValue) => {
    treeStateChangeEvent({expandedKeys: expandedKeysValue})
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue, {checkedNodes}) => {
    treeStateChangeEvent({checkedKeys: checkedKeysValue});
    const selectedCategories: string[] = Array.from(new Set(
      checkedNodes.map(node => {
        return treeData[node.key.split('-')[0]].title
      })
    ))
    setParentCategoriesChecked(selectedCategories);
    parentCategoriesCheckedStateEvt({noTagsParents: selectedCategories});

    setCheckedKeys(checkedKeysValue);
    const newLimitCategories = categories.filter(e => selectedCategories.includes(e.name));
    setLimitCategories(newLimitCategories);
  };

  const onSelect = (selectedKeysValue, info: any) => {
    setSelectedKeys(selectedKeysValue);
  };

  // GENDER CHECKBOX

  const onChangeGenders = (checkedGenderValues: CheckboxValueType[]) => {
    setCheckedGenders(checkedGenderValues);
  };

  const dispatchFilter = () => {
    // const selectedNode = checkedKeys.map(value => (value as string).split('_key')[0]);
    const selectedCategoryTag = checkedKeys.reduce((categoryTags, key: string) => {
      const [categoryIndex, tagIndex] = key.split('-')
      if(tagIndex === undefined) return categoryTags;
      const category = treeData[categoryIndex]
      const tag = category.children[tagIndex]

      categoryTags[category.title] = categoryTags[category.title] || []
      categoryTags[category.title].push(tag.title)

      return categoryTags;
    }, {})

    noTagsChecked.forEach((category: string) => {
      if (selectedCategoryTag[category]) {
        selectedCategoryTag[category] = [""];
      }
    })

    callBack(
      selectedCategoryTag, 
      checkedGenders, 
      JSON.parse(JSON.stringify(limitCategories)),
      maxTimes,
      portalsSelected,
      companiesSelected
    );
  }

  const clearFilters = (): void => {
    localStorage.removeItem('filterStorage');
    setCheckedGenders([]);
    setLimitCategories([]);
    setExpandedKeys([]);
    setCheckedKeys([]);
    setSelectedKeys([]);
    clearFilterEvent();
    setMaxTimesChecked(false);
    setMaxTimes(0);
    setIgnoreChecked(false);
    setPortalsSelected([]);
    setCompaniesSelected([]);

  }

  const onUsedChange = (e: CheckboxChangeEvent) => {
    const state: boolean = e.target.checked;
    setMaxTimesChecked(state);
    if (!state) {
      setMaxTimes(0);
    }
  }
  const onIgnoreChange = (e: CheckboxChangeEvent) => {
    const state: boolean = e.target.checked;
    setIgnoreChecked(state);
    if (!state) {
      setPortalsSelected([]);
    }
  }

  const onUsedValueChange = (e: any) => {
    const value = Number(e.target.value);
    setMaxTimes(value);
  }

  const changeAllowShuffle = (e: CheckboxChangeEvent) => {
    allowShuffleEvt(e.target.checked);
  };

  const onChangeNoTagsForCategory = (e: any[]) =>{
    setNoTagsChecked(e);
    parentCategoriesCheckedStateEvt({noTagsParentsChecked: e});
  }

  useEffect(() => {
    const storage = localStorage.getItem('filterStorage');
    const filtersStorage = JSON.parse(storage || '{}');
    if (!Object.keys(filtersStorage).length) {
      return;
    }
    if (filtersStorage.newSelectedGender?.length) {
      setCheckedGenders(filtersStorage.newSelectedGender);
    }
    if (filtersStorage.newLimitCategories?.length) {
      setLimitCategories(filtersStorage.newLimitCategories);
    }
    if (filtersStorage.expandedKeys?.length) {
      setExpandedKeys(filtersStorage.expandedKeys);
    }
    if (filtersStorage.checkedKeys?.length) {
      setCheckedKeys(filtersStorage.checkedKeys);
    }
    if (filtersStorage.maxTimes) {
      setMaxTimes(filtersStorage.maxTimes);
      setMaxTimesChecked(true);
    }
    if (filtersStorage.allowShuffle) {
      if (filtersStorage.allowShuffle) {
        allowShuffleEvt(true);
      } else {
        allowShuffleEvt(false);
      }
    }
    if (filtersStorage.noTagsParents) {
      setParentCategoriesChecked(filtersStorage.noTagsParents);
    }
    if (filtersStorage.noTagsParentsChecked) {
      setNoTagsChecked(filtersStorage.noTagsParentsChecked);
    }

    return () => {
    }
  }, [])

  return (
    <div>

      <Row>
        <Col span={24}>
          <Button  type="primary" onClick={clearFilters}>Clear Filters</Button>
          
          {
            !!parentCategoriesChecked.length && (
              <>
                <p style={{ fontSize: 13, marginBottom: 0 }}><b>"No tags" when filter for</b></p>
                <Checkbox.Group
                  style={{ width: '100%' }}
                  defaultValue={noTagsChecked}
                  onChange={onChangeNoTagsForCategory}
                >
                  <Row>
                    {
                      parentCategoriesChecked.map((item, index) => (
                        <Col key={index} span={24}>
                        <Checkbox key={index} value={item}>{ item }</Checkbox>
                      </Col>
                      ))
                    }
                  </Row>
                </Checkbox.Group>
              </>
            )
        }
          
          <Title level={5}>1. CATEGORIES/TAGS</Title>
         
          <Tree
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            treeData={treeData}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={5}>2. GENDER</Title>
          <GenderStyle>
            <Checkbox.Group options={genderOptions} value={checkedGenders} onChange={onChangeGenders} />
          </GenderStyle>
        </Col>
      </Row>
      <Row>
      <Col span={24}>
        <Title level={5}>3. USED <Checkbox checked={maxTimesChecked} onChange={onUsedChange}></Checkbox></Title>
          {maxTimesChecked && <p>Max times <Input style={{ width: '30%', padding: '0px' }}
            value={maxTimes}
            onChange={onUsedValueChange} /></p>}
      </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={5}>4. LIMIT</Title>
          {
            limitCategories.map(category => (
                <Row key={category.id} style={{margin: '5px'}}>
                  <Col>
                    {category.name}
                  </Col>
                  <Col>
                  : <Input
                      style={{ width: '30%', padding: '0px' }}
                      value = {category.limit}
                      onChange={e => {
                        category.limit = e.target.value;
                        forceUpdate();
                      }}
                    />
                  </Col>
                </Row>
            ))
          }
        </Col>
      </Row>
      <Row>
      <Col span={24}>
        <Title level={5}>5. IGNORE <Checkbox checked={ignoreChecked} onChange={onIgnoreChange}></Checkbox></Title>
          {ignoreChecked && <div>Ignore what is used in portals:  <Select
              style={{ width: '100%', padding: '0px' }}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder="Please select portal"
              mode="multiple"
              onChange={handlePortalChange}
              options={portalList.map(e => {
                return {
                  value: e.name,
                  label: e.name
                }
              })}
            /></div>}
          {ignoreChecked && <div>Ignore what is used in companies:  <Select
              style={{ width: '100%', padding: '0px' }}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder="Please select company"
              mode="multiple"
              onChange={handleCompanyChange}
              options={companyList.map(e => {
                return {
                  value: e.id,
                  label: e.name
                }
              })}
            /></div>}
       
      </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={5}>6. CONFIG</Title>
          <Checkbox checked={allowShuffle} onChange={changeAllowShuffle}></Checkbox>
          <span> Allow Shuffle</span>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button  type="primary" onClick={dispatchFilter}>View</Button>
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
