import { ContentHub } from '@/types';
import { Input, Modal, Select, Collapse, Button, Popconfirm, Tree  } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getTagsCategoriesGenders } from '../services/edit-database';
import type { DataNode } from 'antd/es/tree';
import { Company as CompanyType } from '../../Company/types';
import { Portal } from '@/pages/Portal/types';

const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

type Prop = {
  showVisible: boolean;
  showVisibleEvent: (contentUpdate?: ContentHub, action?: 'UPDATE' | 'DELETE' | 'SYNC') => void;
  data: ContentHub;
  type?: 'edit_database' | 'push_history_detail' | string;
  companyList?: CompanyType[];
  portalList?: Portal[];
};

const EditTableCell = (
  { 
    data, showVisibleEvent, showVisible, type, companyList, portalList 
  }: Prop) => {
  const langs = [
    { name: 'en', value: 'en' },
    { name: 'es', value: 'es' },
    { name: 'it', value: 'it' },
    { name: 'fr', value: 'fr' },
    { name: 'ch', value: 'ch' },
    { name: 'pt', value: 'pt' },
  ];
  const [content, setContent] = useState<ContentHub>(data);
  const [categories, setCategories] = useState<
    { name: string; slug: string }[]
  >([]);
  const [tags, setTags] = useState([]);
  const [genders, setGenders] = useState([]);

  const { data: tagsCategoriesGenders } = useQuery(
    'tagsCategoriesGenders',
    getTagsCategoriesGenders
  );

  const handleModalOk = () => {
    showVisibleEvent(content, 'UPDATE');
  };
  const handleModalOkSync = () => {
    showVisibleEvent(content, 'SYNC');
  };

  const handleModalCancel = () => {
    showVisibleEvent();
  };

  const onChangeRow = (key: string, value: string) => {
    setContent({ ...content, [key]: value });
  };

  const handleSelectChange = (value: string | string[], key: string) => {
    if (key === 'tags') {
      setContent({ ...content, [key]: value.toString() });
      return;
    }
    const newContent = { ...content, [key]: value };
    setContent(newContent);
  };

  const confirm = (e: React.MouseEvent<HTMLElement> | any) => {
    handleModalOk()
  };
  const confirmSync = (e: React.MouseEvent<HTMLElement> | any) => {
    handleModalOkSync()
  };

  const cancel = (e: React.MouseEvent<HTMLElement> | any) => {

  };

  const companiesTree = () => {
    const used = content?.used?.split(',') || [];
    const removeDup = [...new Set(used)];
    if (!companyList || !portalList) {
      return [];
    }
    const myMap = new Map();
    removeDup.forEach(portal => {
      const portalFind = portalList.find(item => item.name === portal);
      if (!portalFind?.companyId) {
        if (!myMap.has('unknown')) {
          myMap.set('unknown', [portal]);
        } else {
          const currentPortal = myMap.get('unknown');
          currentPortal.push(portal)
        }
        return;
      }
      const companyFind = companyList.find(item => item.id === portalFind?.companyId);
      if (!companyFind?.name) {
        return;
      }
      if (!myMap.has(companyFind.name)) {
        myMap.set(companyFind.name, [portal]);
      } else {
        const currentPortal = myMap.get(companyFind.name);
        currentPortal.push(portal)
      }
    }); 

    const tree: DataNode[] = [];
    for (const [key, value] of myMap) {
      if (Array.isArray(value)) {
        const children = value.map(val => ({ title: val, key: val }));
        tree.push({
          title: key,
          key,
          children,
        })
      }
    }
    return tree;
  };

  useEffect(() => {
    if (tagsCategoriesGenders?.length === 3) {
      setTags(tagsCategoriesGenders[0]);
      setCategories(tagsCategoriesGenders[1]);
      setGenders(tagsCategoriesGenders[2]);
    }
  }, [tagsCategoriesGenders]);

  return (
    <Modal
      title={"Edit " + content.title_en}
      open={showVisible}
      onCancel={handleModalCancel}
      width={1200}
      footer={
        type === 'push_history_detail'
          ? [<Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>]
          : [
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Popconfirm
              key='confirm'
              title="Are you sure to save these changes?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button key="save"  type='primary'>
                Save Changes
              </Button>
            </Popconfirm>,
            <Popconfirm
              key='confirmsync'
              title="Are you sure to save these changes & sync to portal?"
              onConfirm={confirmSync}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button key="savensync"  type='primary'>
                Save Changes & Sync
              </Button>
            </Popconfirm>
          ]}
    >
      <Collapse defaultActiveKey={['title_1']} >
        <Panel header="Title" key="title_1">
          {
            langs.map((lang, index) => (
              <div key={index + lang.value}>
                <span><b>{lang.value}</b></span>
                <TextArea
                  readOnly={type === 'edit_database' ? false : true}
                  rows={1}
                  value={content['title_' + lang.value]}
                  onChange={(e) => onChangeRow('title_' + lang.value, e.target.value)}
                />
              </div>
            ))
          }
        </Panel>
      </Collapse>

      <p>
        <b>Image</b>
      </p>
      <div className="row">
        <div className="col-md-2">
          <img style={{ width: '160px' }} src={content.image} alt="" />
        </div>
        <div className="col-md-10">
          <TextArea
            rows={2}
            value={content.image}
            onChange={(e) => onChangeRow('image', e.target.value)}
          />
        </div>
      </div>

      <p>
        <b>Slug</b>
      </p>
      <TextArea
        readOnly={type === 'edit_database' ? false : true}
        rows={1}
        value={content.slug}
        onChange={(e) => onChangeRow('slug', e.target.value)}
      />

      <p>
        <b>Companies</b>
      </p>
      <div>
      <Tree
        showLine={true}
        defaultExpandAll={true}
        treeData={companiesTree()}
      />
      </div>
        
      <br />
      <p>
        <b>Used</b>
      </p>
      <TextArea
        rows={1}
        value={content.used}
        onChange={(e) => onChangeRow('used', e.target.value)}
      />

      <div className="row">
        <div className="col-md-3">
          <p>
            <b>Category</b>
          </p>
          <Select
            defaultValue={data.category}
            style={{ width: 120 }}
            onChange={(value) => handleSelectChange(value, 'category')}
          >
            {categories.map(({ slug, name }, index: number) => (
              <Option key={'categories' + index} value={name}>
                {name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="col-md-6">
          <p>
            <b>Tags</b>
          </p>
          <Select
            mode="multiple"
            style={{ width: 440 }}
            defaultValue={data.tags?.split(',')}
            onChange={(value) => handleSelectChange(value, 'tags')}
          >
            {tags.map(({ slug, name }, index: number) => (
              <Option key={'tags' + index} value={slug}>
                {name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="col-md-3">
          <p>
            <b>Gender</b>
          </p>
          <Select
            defaultValue={data.gender}
            style={{ width: 120 }}
            onChange={(value) => handleSelectChange(value, 'gender')}
          >
            {genders.map(({ slug, name }, index: number) => (
              <Option key={'genders' + index} value={slug}>
                {name}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      <p>
        <b>description</b>
      </p>
      <Collapse defaultActiveKey={['description_en']} >
        {
          langs.map((lang) => (
            <Panel header={'description_' + lang.value} key={"description" + lang.value}>
              <TextArea
                readOnly={type === 'edit_database' ? false : true}
                rows={15}
                value={content['description_' + lang.value]}
                onChange={(e) => onChangeRow('description_' + lang.value, e.target.value)}
              />
            </Panel>
          ))
        }
      </Collapse>
    </Modal>
  );
};

export default EditTableCell;
