import React, { useState } from 'react';
import { Button } from 'antd';
import PushSettings from '../components/push-settings';
import { ContentHub } from '@/types';
import { DataPush } from '../types';
import { Portal } from '@/pages/Portal/types';

type Prop = {
  selectedContentHubs: ContentHub[];
  portalList: Portal[];
  viewListEvent: () => void;
  viewTableEvent: () => void;
  uncheckAllEvent: () => void;
  selectAllEvent: () => void;
  dataPushEvent: (data: DataPush, selectedPortal: Portal[]) => void;
  editContentHub: () => void;
  addToCartEvt: () => void;
};

const HeaderButtons = (props: Prop) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const modalVisibleEvent = (open: boolean) => {
    setIsModalVisible(open);
  };

  const viewList = (): void => {
    props.viewListEvent();
  };

  const viewTable = (): void => {
    props.viewTableEvent();
  };

  const dataPushEvent = (data: DataPush, selectedPortal: Portal[]) => {
    props.dataPushEvent(data, selectedPortal);
    setIsModalVisible(false);
  };

  return (
    <div className="pt-3 d-flex justify-content-between">
      <div>
        <Button onClick={viewList}>View List</Button>
        {' '}
        <Button onClick={viewTable}>View Table</Button>
      </div>
      <div>
        {props.selectedContentHubs.length === 1 && (
          <Button type="primary" danger ghost onClick={props.editContentHub}>Edit</Button>
        )}
        {' '}
        <Button onClick={props.uncheckAllEvent}>Uncheck All</Button>
        {' '}
        <Button onClick={props.selectAllEvent}>Select All</Button>
        {' '}
        <Button
          type="primary" ghost
          disabled={props.selectedContentHubs.length === 0}
          onClick={props.addToCartEvt}
        >
          Add to Cart
        </Button>
        {' '}
        <Button
          type="primary" danger ghost
          onClick={showModal}
        >
          Push
        </Button>
        <PushSettings
          portalList={props.portalList}
          routeFrom='viewAndPushPage'
          selectedContentHubs={props.selectedContentHubs}
          dataPushEvent={dataPushEvent}
          isModalVisible={isModalVisible}
          modalVisibleEvent={modalVisibleEvent}
        />
      </div>
    </div>
  );
};

HeaderButtons.defaultProps = {
  selectedContentHubs: [],
};

export default HeaderButtons;
