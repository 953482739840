import React from 'react';
import { Checkbox } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import styled from 'styled-components';

const CustomCheckGroup = styled.div`
  & .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
`;

type Prop = {
  onChange: Function;
}

const FilterModal = (props: Prop) => {
  const plainOptions = [ 'en', 'es', 'it', 'fr', 'ch', 'pt'];
  
  const onChange = (checkedValues: CheckboxValueType[]) => {
    props.onChange(checkedValues);
  };

  return (
    <>
      <h3 className="text-uppercase">languages</h3>
      <CustomCheckGroup>
        <Checkbox.Group
          options={plainOptions}
          defaultValue={['Apple']}
          onChange={onChange}
        />
      </CustomCheckGroup>
    </>
  );
};
export default FilterModal;
