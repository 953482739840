import { getItemById } from '@/pages/Company/services/company.services';
import { Company } from '@/pages/Company/types';
import AppTable from '@/pages/components/AppTable';
import React from 'react';

type Prop = {
  total: number;
  page: number;
  pageSize: number;
  data: Record<string, any>[];
  selectedRowKeys?: React.Key[];
  setPagination: Function;
  setSelectedRowKeys: Function;
  companyList: Company[],
};

const PortalTable = (props: Prop) => {
  const getCompanyNameById = (id:number) => {
    let temp = props.companyList.find(e => id === e.id);
    return temp?.name || null;
  }
  const columns = [
    // { title: 'ID', dataIndex: 'id' },
    {
      title: 'NAME',
      dataIndex: 'name',
      editable: true,
    },
    { title: 'URL', dataIndex: 'url', editable: true },
    { title: 'COMPANY', 
      dataIndex: 'companyId', 
      render: (value : number) => getCompanyNameById(value),
      editable: true },
  ];

  const setSelectedRowKeys = (keys: number[]) => {
    props.setSelectedRowKeys(keys);
  };

  return (
    <div>
      <AppTable
        columns={columns}
        data={props.data}
        page={props.page}
        total={props.total}
        pageSize={props.pageSize}
        selectedRowKeys={props.selectedRowKeys}
        setPagination={props.setPagination}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </div>
  );
};

export default PortalTable;
