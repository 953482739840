import { ContentHub } from '@/types';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { syncToWP } from '../ViewAndPush/services/wordpress.services';
import { DataSync } from '../ViewAndPush/types';
import EditTable from './components/edit-table';
import {
  deleteContentHubs,
  getCount,
  getList,
  updateContentHub,
} from './services/edit-database';
import { Company as CompanyType } from '../Company/types';
import { Portal } from '@/pages/Portal/types';
import { getList as getListCompany } from '../Company/services/company.services';
import { getList as getListPortal } from '../Portal/services/portal.services';

type Prop = {};

const EditDatabase = (_: Prop) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [total, setTotal] = useState<number>(0);
  const [dataList, setDataList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [orderFilter, setOrderFilter] = useState<string>('id DESC');
  const [loadingTable, setLoadingTable] = useState<boolean>(false); 

  const [companyList, setCompanyList] = useState<CompanyType[]>([]);
  const [portalList, setPortalList] = useState<Portal[]>([]);

  const fetchTotal = async () => {
    const filter: Record<string, any> = { order: orderFilter, where: {title_en: {regexp: searchValue} }};
    let result = await getCount(filter);
    setTotal(result);
  }
  const fetchDataList = async () => {
    setLoadingTable(true);
    let result = await getList({skip: (page - 1) * pageSize, limit: pageSize, order: orderFilter, where: {title_en: {regexp: searchValue} }});
    setDataList(result);
    setLoadingTable(false);
  }
  useEffect(()=>{
    Promise.all([fetchTotal(), fetchDataList()]);
  },[page, pageSize, searchValue, orderFilter]);

  const setPaginationEvt = (data: { page: number; pageSize: number, order: string }) => {
    setPage(data.page);
    setPageSize(data.pageSize);
    if (data.order) {
      setOrderFilter(data.order);
    }
  };

  const syncToPortal = async (data: DataSync): Promise<void> => {
    const myArray = data?.post?.used?.split(",");
    let uniqueArray = [...new Set(myArray)]; // remove duplicate element
    uniqueArray = uniqueArray.filter(e => e); // remove empty element
    uniqueArray = uniqueArray.map(e => { // remove '/' character
      const last = e.charAt(e.length - 1);
      if (last === '/')
        return e.slice(0, -1)
      return e
    });
    let selectedPortal = uniqueArray;
    Promise.all(
      selectedPortal.map((portal: string) => syncToWP(data, portal + '/wp-json/ccpplugin/v1/update_post'))
    );
  };

  const setDataEvt = async (evt: {
    data: ContentHub | number[] | any;
    action: string;
  }) => {
    if (!evt.action) {
      return;
    }
    // if (evt.action === 'DELETE') {
    //   await deleteContentHubs(evt.data);
    //   fetchTotal();
    //   fetchDataList();
    //   return;
    // }
    const result = await updateContentHub(evt.data);
    const indexOfContent = dataList?.findIndex(content => content.id === evt.data.id);
    if (indexOfContent >= 0) {
      dataList[indexOfContent] = result;
    }
    if(evt.action === 'SYNC') {
      await syncToPortal({post : evt.data});
    }
  };

  const deleteRowEvent = async (id: number) => {
    await deleteContentHubs([id]);
  }
  const onSearch = async (value: string) => {
    setSearchValue(value);
    setPage(1);
  };

  useEffect(() => {
    getListPortal().then(res => { setPortalList(res) })
    getListCompany().then(res => setCompanyList(res));
  }, []);

  return (
    <>
      <Input.Search  
        onSearch={onSearch}
        placeholder='input search text' 
        style={{width: 200}}
      />
      <EditTable
        loadingTable={loadingTable}
        disableSearch={true}
        type='edit_database'
        page={page}
        total={total}
        data={dataList}
        pageSize={pageSize}
        setData={setDataEvt}
        setPagination={setPaginationEvt}
        deleteRowEvent={deleteRowEvent}
        companyList={companyList}
        portalList={portalList}
      />
    </>
  );
};

export default EditDatabase;
