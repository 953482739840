import { ContentHub, Filter } from './../../../types';
import axios from 'axios';

export const getList = async (filter: Filter = {}) => {
    try {
        const { data } = await axios.get('/ContentHubs', {
            params: {
                filter,
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getCount = async (filter: Filter = { where:{}}) => {
    try {
        const { data } = await axios.get('/ContentHubs/count', {
            params: {
                where: filter.where,
            },
        });
        return data?.count || 0;
    } catch (error) {
        throw new Error(error);
    }
};

const getCategories = async () => {
    try {
        const { data } = await axios.get('/Categories/', {
            params: {
                where: {},
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

const getTags = async () => {
    try {
        const { data } = await axios.get('/Tags', {
            params: {
                where: {},
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

const getGender = async () => {
    try {
        const { data } = await axios.get('/Genders', {
            params: {
                where: {},
            },
        });
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getTagsCategoriesGenders = async () => {
    const result = await Promise.all([getTags(), getCategories(), getGender()]);
    return result;
};

export const updateContentHub = async (contentHub: ContentHub) => {
    try {
        const { data } = await axios.patch('/ContentHubs', contentHub);
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const deleteContentHubs = async (ids: number[]) => {
    try {
        await Promise.all(ids.map((id: number) => axios.delete(`/ContentHubs/${id}`)));
    } catch (error) {
        throw new Error(error);
    }
};
