const configs: Record<string, Record<string, any>> = {
    production:  {
        BASE_URL: 'https://own-contenture-api.pml-tech.com/api',
    },
    development: {
        // BASE_URL: 'http://localhost:4000/api',
        BASE_URL: 'https://own-contenture-api.pml-tech.com/api',

    }
}
export const cms_version = 'v2.4';
export default Object.assign({}, configs[import.meta.env.MODE], {env: import.meta.env})
